<template>
<opm-page :show-modal="showModal" modal-subtitle="下载App获取最新汇率" @hide="showModal = false">
  <ion-content>
    <ion-item style="position: relative" lines="none">
      <ion-avatar slot="start">
        <img src="/assets/CNY@2x.png"/>
      </ion-avatar>
      <ion-label>CNY</ion-label>
      <ion-label slot="end" @click="showModal=true">100</ion-label>
      <div style="position: absolute; bottom: 0; right: 12px; font-size: 12px; color: #BBBDC4">人民币 ￥</div>
      <div style="position: absolute; top: 0; right: 12px; font-size: 12px; color: #BBBDC4">兑换金额</div>
    </ion-item>
    <div style="background: #F5F6FA;height: 20px"></div>
    <ion-list>
      <ion-item v-for="(cur, ind) in currencies" :key="ind" style="position: relative">
        <ion-avatar slot="start">
          <img :src="`/assets/${cur.currency}@2x.png`"/>
        </ion-avatar>
        <ion-label>{{ cur.currency }}</ion-label>
        <ion-label slot="end">{{currencyRate[cur.currency]}}</ion-label>
        <div style="position: absolute; bottom: 0; right: 12px; font-size: 12px; color: #BBBDC4">{{ cur.name }}</div>
      </ion-item>
    </ion-list>
  </ion-content>
</opm-page>
</template>

<script>
import OpmPage from "@/components/OpmPage";
import { IonContent, IonList, IonItem, IonLabel, IonAvatar, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Rate",
  data () {
    return {
      showModal: false,
      currencies: [
        {currency: 'AUD', name: '澳币 A$'},
        {currency: 'USD', name: '美元 $'},
        {currency: 'GBP', name: '英镑 ￡'},
        {currency: 'NZD', name: '新西兰元NZ $'},
        {currency: 'EUR', name: '欧元 €'},
        {currency: 'CAD', name: '加拿大元 C$'},
        {currency: 'JPY', name: '日元JPY ￥'},
        {currency: 'HKD', name: '港币HKD ￥'},
      ],
      currencyRate: {}
    }
  },
  mounted() {
    document.title = '国际汇率'
    this.$axios.get('/tool/exchange/rate').then(resp => {
      resp.result.forEach((item) => {
        this.currencyRate[item.currency] = (100 / (item.bankConversionPrice / 100)).toFixed(2)
      })
    })
  },
  components: { OpmPage, IonContent, IonItem, IonList, IonAvatar, IonLabel }
})
</script>

<style scoped>

</style>